import WqmsError from "./WQMSError.mjs";

const REQ_TIMEOUT_MILLISECONDS = 20000;

const API_URL_TYPE = Object.freeze({
  local: "http://localhost:7071/api",
  staging: "https://rt-telemetry-staging.azurewebsites.net/api",
  production: "https://rt-telemetry.azurewebsites.net/api",
});

const EVENTS_API_TYPE = Object.freeze({
  events: "getEvents",
  alerts: "getAlerts",
  audit: "getAudit",
});

async function fetchData(url) {
  let responseData;

  try {
    //console.debug("Starting to send request now.");
    await fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate, br, zstd",
        "Accept-language": "en-US",
      },
      signal: AbortSignal.timeout(REQ_TIMEOUT_MILLISECONDS),
    })
      .then((res) => res.json())
      .then((data) => {
        //console.debug("Got response now.");
        if (data.error) {
          throw new WqmsError(data.error, data.status);
        }

        responseData = data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (err) {
    if (err.name === "TimeoutError") {
      console.error(
        `Timeout: fetch took more than ${REQ_TIMEOUT_MILLISECONDS / 1000} seconds to get the result!`,
        err.message,
      );
    } else if (err.name === "AbortError") {
      console.error(
        "Fetch aborted by user action (browser stop button, closing tab, etc.",
        err.message,
      );
    } else if (err.name === "TypeError") {
      console.error(
        "AbortSignal.timeout() method is probably not supported",
        err.message,
      );
    } else if (err.name === "WQMSError") {
      console.error("Backend Error", err.message);
    } else {
      // A network error, or some other problem.
      console.error(`Error: type: ${err.name}, message: ${err.message}`);
    }
  }

  return responseData;
}

async function getTelemetryForTime(
  { deviceId, user, isRealtime: rt = true, fromISODate, toISODate },
  environment = "production",
) {
  const url = new URL(`${API_URL_TYPE[environment]}/getTelemetry`);

  const params = new URLSearchParams({
    user,
    deviceId,
    fromISODate,
    toISODate,
    rt,
  });

  url.search = params.toString();

  return await fetchData(url);
}

async function getDeviceHierarchy(user, environment = "production") {
  const url = new URL(`${API_URL_TYPE[environment]}/getDeviceHierarchy`);

  const params = new URLSearchParams({
    user,
  });

  url.search = params.toString();

  return await fetchData(url);
}

async function getRules(orgId, environment = "production") {
  const url = new URL(`${API_URL_TYPE[environment]}/getOrgRules`);

  const params = new URLSearchParams({
    orgId,
  });

  url.search = params.toString();

  return await fetchData(url);
}

function getEventsUrlByType(eventType, config, environment = "production") {
  const url = new URL(
    `${API_URL_TYPE[environment]}/${EVENTS_API_TYPE[eventType]}`,
  );
  const params = new URLSearchParams(config);
  url.search = params.toString();

  return url;
}

/**
 * @typedef {Object} ReqConfig
 * @property {string} user - User email
 * @property {string} deviceId
 * @property {string} fromISODate - in ISO format (UTC timezone)
 * @property {string} toISODate - in ISO format (UTC timezone)
 */

/**
 * Get events based on the eventType
 * @param {ReqConfig} config Request configuration
 * @param {string} eventType events|alerts|audit
 * @param {string} environment local|staging|production
 * @returns {Array} Array of IoTEvent
 */
async function getEvents(config, eventType, environment = "production") {
  const url = getEventsUrlByType(eventType, config, environment);

  return await fetchData(url);
}

export default fetchData;
export { getTelemetryForTime, getDeviceHierarchy, getRules, getEvents };
