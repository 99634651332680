import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

/** User account and settings can be updated from here. */
export default function Settings() {
  return (
    <>
      <Link to="/">Back</Link>
      <h1>Settings go here</h1>
      <Button variant="contained">Click me!</Button>
    </>
  );
}
