import ConstantsHelper from "../ConstantsHelper.mjs";
/**
  @class Operand
  @private
  @type {Object}
  @param {string} name The name of the operand contains the telemetry property name such as "turb" or "ph". Please see the static class OperandName above.
  @param {Object} value The value for this operand can be of types below. Please see "type" param.
  @param {string} type The type of the value for this operand: number, string, boolean, null
*/

/**
  @class Operator
  @private
  @type {Object}
  @param {string} displayName The name of the operator used in the UI.  Please see the static class OperatorName above.
  @param {string} value The name of the operator used for comarisons. Please see the static class OperatorType above.
*/

/**
 * Representation of Alert condition inside the Rule conditions array.
 * @param {Operand} operand Operand represents the first operand (the left side) of the comparison. It contains the name of telemetry property and data type.
 * @param {Operator} context
 * @param {Operand} operandValue OperandValue represents the secong operand (the right side) of the comparison.
 *                                  The actual value for the operand of data type declared inside the operand.
 */
export default class RuleCondition {
  operand;
  operator;
  operandValue;

  constructor(leftOperand, operator, rightOperand) {
    this.operand = leftOperand;
    this.operator = operator;
    this.operandValue = rightOperand;
  }

  clone() {
    return new RuleCondition(
      this.operand.clone(),
      this.operator.clone(),
      this.operandValue.clone(),
    );
  }

  toJSON() {
    return {
      operand: this.operand.toJSON(),
      operator: this.operator.toJSON(),
      operandValue: this.operandValue.toJSON(),
    };
  }

  toString() {
    return `${ConstantsHelper.WaterPropertyDictionary[this.operand.name]} is ${this.operator.displayName} ${this.operandValue.value}`;
  }
}
