import React from "react";
import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function NoData() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <InfoOutlinedIcon />
      <Typography variant="string" component="p" sx={{ marginLeft: "5px" }}>
        No data has been received for this device yet.
      </Typography>
    </Box>
  );
}
