import IoTEvent from "./IoTEvent.mjs";

const AUDIT_MSG_TYPE = "audit";

export default class IoTAuditEvent extends IoTEvent {
  static NOT_APPLICABLE = "NA";

  actor;
  updated;
  resource;

  constructor(
    id,
    applicationId,
    deviceId = IoTAuditEvent.NOT_APPLICABLE,
    org,
    enqueuedTime,
    messageSource = AUDIT_MSG_TYPE,
    messageType = null,
    templateId,
    actor,
    updated = null,
    resource = null,
  ) {
    super(
      id,
      applicationId,
      deviceId,
      null,
      org,
      enqueuedTime,
      messageSource,
      messageType,
      templateId,
    );
    this.actor = actor;
    this.updated = updated;
    this.resource = resource;
  }

  clone() {
    return new IoTAuditEvent(
      this.id,
      this.applicationId,
      this.deviceId,
      this.deviceName,
      this.orgId,
      this.enqueuedTime,
      this.messageSource,
      this.messageValue,
      this.templateId,
      this.actor,
      this.updated,
      this.resource,
    );
  }

  toJSON() {
    return {
      id: this.id,
      applicationId: this.applicationId,
      deviceId: this.deviceId,
      deviceName: this.deviceName,
      orgId: this.orgId,
      enqueuedTime: this.enqueuedTime,
      messageSource: this.messageSource,
      messageValue: this.messageValue,
      templateId: this.templateId,
      actor: this.actor,
      updated: this.updated,
      resource: this.resource,
    };
  }
}
