/**
 * Representation of one operator inside the Alert condition which is inside the Rule.
 * @param {string} displayName The name of the operator used in the UI.  Please see the static class OperatorName above.
 * @param {string} value The name of the operator used for comarisons. Please see the static class OperatorType above.
 */
export default class Operator {
  // enum-like property usage new Operator(Operator.OperatorType.IS)
  static OperatorType = Object.freeze({
    GT: ">",
    GTE: ">=",
    LT: "<",
    LTE: "<=",
    EQ: "=",
    IS: "=",
  });

  // enum-like property usage new Operator(Operator.OperatorName.IS)
  static OperatorName = Object.freeze({
    GT: "greater than",
    GTE: "greater or equal to",
    LT: "less than",
    LTE: "less than or equal to",
    EQ: "equals",
    IS: "is",
  });

  value;
  displayName;

  constructor(displayName, value) {
    this.displayName = displayName;
    this.value = value;
  }

  clone() {
    return new Operator(this.displayName, this.value);
  }

  toJSON() {
    return {
      displayName: this.displayName,
      value: this.value,
    };
  }
}
