import React from "react";

export default function Administration() {
  return (
    <>
      <h3>Effusive (WQMS) v1.0</h3>
      <p>Administration....</p>
      <h5>Copyright: Renaissance Environmental Ltd.</h5>
      <h5>All rights reserverd.</h5>
    </>
  );
}
