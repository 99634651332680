import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import Dash from "./pages/Dash";
import AboutUs from "./pages/AboutUs";
import Settings from "./pages/Settings";
import Project from "./pages/Project.mjs";
import EditProject from "./pages/EditProject.mjs";
import store from "./store/store.mjs";
import Projects from "./pages/Projects.mjs";
import Landing from "./pages/Landing.mjs";
import Error from "./pages/Error.mjs";
import Events from "./pages/Events.mjs";
import Alerts from "./pages/Alerts.mjs";
import Rules from "./pages/Rules.mjs";
import Forecast from "./pages/Forecast.mjs";
import Administration from "./pages/Administration.mjs";
import Audit from "./pages/Audit.mjs";

// Clear the existing HTML content
document.body.innerHTML = '<main id="app"></main>';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

//Define routes
const router = createBrowserRouter([
  {
    element: <Dash instance={msalInstance} />,
    children: [
      {
        path: "/",
        index: true,
        element: <Landing />,
        errorElement: <Error />,
      },
      {
        path: "projects",
        element: <Projects />,
        errorElement: <Error />,
      },
      {
        path: "projects/:orgId/devices/:deviceId",
        element: <Project />,
        errorElement: <Error />,
      },
      {
        path: "projects/:orgId/devices/:deviceId/alerts",
        element: <Alerts />,
        errorElement: <Error />,
      },
      {
        path: "projects/:orgId/devices/:deviceId/events",
        element: <Events />,
        errorElement: <Error />,
      },
      {
        path: "projects/:orgId/devices/:deviceId/edit",
        element: <EditProject />,
        errorElement: <Error />,
      },
      {
        path: "administration",
        element: <Administration />,
        errorElement: <Error />,
      },
      {
        path: "forecast",
        element: <Forecast />,
        errorElement: <Error />,
      },
      {
        path: "rules",
        element: <Rules />,
        errorElement: <Error />,
      },
      {
        path: "audit",
        element: <Audit />,
        errorElement: <Error />,
      },
      {
        path: "about",
        element: <AboutUs />,
        errorElement: <Error />,
      },
      {
        path: "settings",
        element: <Settings />,
        errorElement: <Error />,
      },
    ],
  },
]);

createRoot(document.getElementById("app")).render(
  <StoreProvider store={store}>
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  </StoreProvider>,
);
