import { action, thunk, createStore, computed } from "easy-peasy";
import { DateTime } from "luxon";

import {
  getTelemetryForTime,
  getDeviceHierarchy,
  getRules,
  getEvents,
} from "./api.mjs";
import OrganizationTree from "./OrganizationTree.mjs";
import Rule from "./rules/Rule.mjs";
import RuleCondition from "./rules/RuleCondition.mjs";
import Operand from "./rules/Operand.mjs";
import Operator from "./rules/Operator.mjs";
import IoTEvent from "./events/IoTEvent.mjs";
import IoTAlert from "./events/IoTAlert.mjs";
import IoTAuditEvent from "./events/IoTAuditEvent.mjs";
import ConstantsHelper from "./ConstantsHelper.mjs";

const expectedWaterSampleProps = {
  dischflow: true,
  dischvol: true,
  ph: true,
  recirflow: true,
  recirvol: true,
  temp: true,
  tempc: true,
  turb: true,
  rain: true,
};

const ignoredWaterSampleProps = {
  location: true,
  flowval: true,
  pressure: true,
  humidity: true,
  description: true,
  maxtemp: true,
  triggerIoTCentralRule: true,
};

const EVENTS_TYPE = Object.freeze({
  events: "events",
  alerts: "alerts",
  audit: "audit",
});

/**
 * Make sure we have the basic props in a sample. If not add them with null values by default.
 * This is done to prevent crashes due to frequent missing water properties in telemetry data.
 */
function hydrateWaterSample(waterSample) {
  for (const expectedWaterProperty in expectedWaterSampleProps) {
    if (!waterSample.hasOwnProperty(expectedWaterProperty)) {
      waterSample[expectedWaterProperty];
    }
  }
}

/**
 * Round float values only. Return all other values unchanged.
 * @param {*} value this can be of many types, but we round only if this is a float value
 * @param {*} precision how may decimals we want as a result
 * @returns
 */
function roundFloat(value, precision) {
  // check if the passed value is a number
  if (typeof value == "number" && !isNaN(value)) {
    // if it is not integer, it's float
    if (!Number.isInteger(value)) {
      const multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }
  }

  return value;
}

function upsertMap(map, itemKey, itemData) {
  if (map.has(itemKey)) {
    map.get(itemKey).push(itemData);
  } else {
    map.set(itemKey, [itemData]);
    //update legend
    if (!map.get("legend").hasOwnProperty(itemKey)) {
      map.get("legend")[itemKey] = String(itemKey).toUpperCase();
    }
  }
}

function durationToTimeRange(duration) {
  const nowUTC = DateTime.now().toUTC();
  let from = null;
  const to = nowUTC.toISO();

  switch (duration) {
    case "1h":
      from = nowUTC.minus({ hours: 1 }).toISO();
      break;
    case "6h":
      from = nowUTC.minus({ hours: 6 }).toISO();
      break;
    case "1d":
      from = nowUTC.minus({ days: 1 }).toISO();
      break;
    case "1w":
      from = nowUTC.minus({ weeks: 1 }).toISO();
      break;
    default:
      from = nowUTC.minus({ hours: 1 }).toISO();
      console.error(
        "Unhandled duration was selected. Defaulting to 1h.",
        duration,
      );
      break;
  }

  return {
    from,
    to,
  };
}

/**
 * Prepare the request parameters for events and alerts API
 * @param {object} localState
 * @param {string} deviceId
 * @param {string} user
 * @param {object} customDateTimeRange
 * @returns {object}
 */
function getReqParamsConfig(
  localState,
  deviceId,
  user,
  customDateTimeRange = null,
) {
  let fromISODate = null;
  let toISODate = null;

  if (customDateTimeRange) {
    fromISODate = customDateTimeRange.from;
    toISODate = customDateTimeRange.to;
  } else {
    //Hence all store states contain a computer property called "timeWindow", because we pass localState in this function.
    //I could not have regular "timeWindow" property the all the local stores with different values,
    //because the easypeasy crashed when I did it, so I had to improvise with computed properties as a workaround that crash.
    const timeRange = durationToTimeRange(localState.timeWindow);
    fromISODate = timeRange.from;
    toISODate = timeRange.to;
  }

  const result = {
    user,
    deviceId,
    fromISODate,
    toISODate,
  };

  return result;
}

function getTelemetryReqConfig(state, payload) {
  const reqConfig = getReqParamsConfig(
    state.telemetry,
    state.currentDeviceId,
    state.currentUser,
  );

  reqConfig.isRealtime = !!payload;

  return reqConfig;
}

/**
 * This function prepares the request parameters for events and alerts API.
 * The payload parameter may contain a custom time range {from, to}.
 * @param {object} state
 * @param {object} payload
 * @returns
 */
function getEventsReqConfig(state, payload) {
  const reqConfig = getReqParamsConfig(
    state.events,
    state.currentDeviceId,
    state.currentUser,
    payload,
  );

  return reqConfig;
}

/**
 * This function prepares the request parameters for getAudit API. The Audit API doesn't need a device ID.
 * The payload parameter may contain a custom time range {from, to}.
 * @param {object} state
 * @param {object} payload
 * @returns
 */
function getAuditReqConfig(state, payload) {
  const reqConfig = getReqParamsConfig(state.audit, null, null, payload);

  delete reqConfig.user;
  //For now we don't need to filter by deviceId in the audit API
  delete reqConfig.deviceId;
  reqConfig.orgId = state.currentUserOrgId;

  return reqConfig;
}

const store = createStore({
  isServiceWorkerActivated: false,
  //global props, actions...
  currentUser: null,
  currentUserOrgId: "vendors-1u5z71p280h",
  //local || staging || production
  environment: "production",

  setServiceWorkerActivated: action((state, payload) => {
    state.isServiceWorkerActivated = payload;
  }),

  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),

  setEnvironment: action((state, payload) => {
    state.environment = payload;
  }),

  updateUser: thunk(async (actions, payload) => {
    try {
      await actions.orgHierarchy.getDeviceHierarchy(payload).then((data) => {
        actions.setCurrentUser(payload);
      });
    } catch (error) {
      console.error(error);
    }
  }),

  currentDeviceId: null,
  currentProjectId: null,

  currentProjId: computed((state) => {
    return state.currentProjectId;
  }),

  currentProjectName: computed((state) => state.currentDeviceId),

  setCurrentDeviceId: action((state, payload) => {
    state.currentDeviceId = payload;
  }),

  setCurrentProjectId: action((state, payload) => {
    state.currentProjectId = payload;
  }),

  //begin scoped props, actions...
  telemetry: {
    data: null,
    //TODO: Implement InnoDB caching to lower telemetryTimeWindow to 2 minutes.
    telemetryTimeWindow: "1h", //default time window
    polling: -2,
    pollingInterval: 60000,
    pollingCounter: 0,
    maxTimePolling: 30,

    //never name a computed property the same as a state property!
    timeWindow: computed((state) => state.telemetryTimeWindow),

    setTimeWindow: action((state, payload) => {
      state.telemetryTimeWindow = payload;
    }),

    setPolling: action((state, payload) => {
      state.polling = payload;
      console.info("Telemetry PollingId: ", state.polling);
    }),

    setPollingCounter: action((state, payload) => {
      state.pollingCounter = payload;
    }),

    stopPolling: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();
      clearTimeout(state.polling);
      await actions.setPolling(-2);
      await actions.setPollingCounter(0);
      console.info("Telemetry Polling cleared...");
    }),

    updatePollingCounter: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();

      if (state.pollingCounter < state.maxTimePolling) {
        await actions.setPollingCounter(state.pollingCounter + 1);
      } else {
        await actions.stopPolling();
      }
    }),

    formatTelemetry: action((state, payload) => {
      if (!payload) return;
      const rawTelemetry = payload;
      const chartsDataMap = new Map();

      chartsDataMap.set("legend", ConstantsHelper.WaterPropertyDictionary);

      for (const telemetryDatum of rawTelemetry) {
        upsertMap(
          chartsDataMap,
          "sampleDate",
          DateTime.fromISO(telemetryDatum?.enqueuedTime),
        );

        const waterSample = telemetryDatum?.telemetry;
        //make sure we have the basic properties in the sample
        hydrateWaterSample(waterSample);

        for (const waterProperty in waterSample) {
          if (expectedWaterSampleProps[waterProperty]) {
            upsertMap(
              chartsDataMap,
              waterProperty,
              roundFloat(waterSample[waterProperty], 2),
            );
          } else if (ignoredWaterSampleProps[waterProperty]) {
            //console.info("Ignoring water property: ", waterProperty);
          } else {
            console.warn("Unexpected water property detected: ", waterProperty);
          }
        }
      }

      state.data = chartsDataMap;
    }),

    getTelemetryForTime: thunk(async (actions, payload, helpers) => {
      try {
        const globalState = helpers.getStoreState();
        const config = getTelemetryReqConfig(globalState, payload);
        await getTelemetryForTime(config, globalState.environment).then(
          (data) => {
            actions.formatTelemetry(data);
          },
        );
      } catch (error) {
        console.error(error);
      }
    }),

    pollTelemetryForTime: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();

      if (state.polling < -1) {
        return; //exit polling
      }

      try {
        await actions.getTelemetryForTime(payload);
      } catch (error) {
        console.error("Error polling telemetry:", error);
        actions.stopPolling();
      } finally {
        if (state.polling < -1) {
          return; //exit polling
        }

        const pollingID = setTimeout(
          () => actions.pollTelemetryForTime(payload),
          state.pollingInterval,
        );

        actions.setPolling(pollingID);
        await actions.updatePollingCounter();
      }
    }),
  },

  orgHierarchy: {
    data: null,
    polling: -2,
    pollingInterval: 60000,
    pollingCounter: 0,
    maxTimePolling: 30,

    //Create a computed property to get a list of device Ids. Used to list events for all devices.
    /* deviceIds: computed((state) => {
      const deviceIds = [];
      state.data?.devices.forEach((device) => {
        deviceIds.push(device.id);
      });
      return deviceIds;
    }), */

    setPolling: action((state, payload) => {
      state.polling = payload;
      console.info("Hierarchy PollingId: ", state.polling);
    }),

    setPollingCounter: action((state, payload) => {
      state.pollingCounter = payload;
    }),

    stopPolling: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();
      clearTimeout(state.polling);
      await actions.setPolling(-2);
      await actions.setPollingCounter(0);
      console.info("Hierarachy Polling cleared...");
    }),

    updatePollingCounter: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();

      if (state.pollingCounter < state.maxTimePolling) {
        await actions.setPollingCounter(state.pollingCounter + 1);
      } else {
        await actions.stopPolling();
      }
    }),

    currentProject: computed((state) => {
      return (projId) => state.data?.getOrganizationById(projId);
    }),

    formatHierarchy: action((state, payload) => {
      if (payload) {
        state.data = new OrganizationTree(payload);
      }
    }),

    getDeviceHierarchy: thunk(async (actions, payload, helpers) => {
      try {
        const globalState = helpers.getStoreState();
        const userId = payload ? payload : globalState.currentUser;
        if (userId) {
          await getDeviceHierarchy(userId, globalState.environment).then(
            (data) => {
              actions.formatHierarchy(data);
            },
          );
        }
      } catch (error) {
        console.error(error);
      }
    }),

    pollHierarchyForTime: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState();

      if (state.polling < -1) {
        return; //exit polling
      }

      try {
        await actions.getDeviceHierarchy(payload);
      } catch (error) {
        console.error("Error polling hierarchy:", error);
        await actions.stopPolling();
      } finally {
        if (state.polling < -1) {
          return; //exit polling
        }
        const pollingID = setTimeout(
          () => actions.pollHierarchyForTime(payload),
          state.pollingInterval,
        ); // Poll again after 60 seconds
        actions.setPolling(pollingID);
        await actions.updatePollingCounter();
      }
    }),
  },

  rules: {
    data: null,

    formatRules: action((state, payload) => {
      if (payload) {
        const rules = payload?.map(
          (rule) =>
            new Rule(
              rule.id,
              rule.orgId,
              rule.isEnabled,
              rule.conditions?.map(
                (condition) =>
                  new RuleCondition(
                    new Operand(
                      condition.operand?.name,
                      condition.operand?.value,
                      condition.operand?.type,
                    ),
                    new Operator(
                      condition.operator?.displayName,
                      condition.operator?.value,
                    ),
                    new Operand(
                      condition.operandValue?.name,
                      condition.operandValue?.value,
                      condition.operandValue?.type,
                    ),
                  ),
              ),
              rule.displayName,
              rule.deviceTemplate,
              rule.severity,
            ),
        );

        state.data = rules;
      }
    }),

    getRules: thunk(async (actions, payload, helpers) => {
      try {
        const environment = helpers.getStoreState().environment;
        //const orgId = payload ? payload : helpers.getStoreState().currentUser;
        const orgId = "city-of-coquitlam-2p9qv3obg2u"; //for now... we have to find out how to get this from logged in user
        await getRules(orgId, environment).then((data) => {
          actions.formatRules(data);
        });
      } catch (error) {
        console.error(error);
      }
    }),
  },

  events: {
    data: null,
    eventsTimeWindow: "1d",

    timeWindow: computed((state) => state.eventsTimeWindow),

    formatEvents: action((state, payload) => {
      let events = [];

      if (payload) {
        events = payload.map((event) => {
          return new IoTEvent(
            event.id,
            event.applicationId,
            event.deviceId,
            event.deviceName,
            event.orgId,
            event.enqueuedTime,
            event.messageSource,
            event.messageValue,
            event.templateId,
          );
        });
      }

      state.data = events;
    }),

    getEvents: thunk(async (actions, payload, helpers) => {
      try {
        const globalState = helpers.getStoreState();
        const reqConfig = getEventsReqConfig(globalState, payload);
        //payload is the event type
        await getEvents(
          reqConfig,
          EVENTS_TYPE.events,
          globalState.environment,
        ).then((data) => {
          actions.formatEvents(data);
        });
      } catch (error) {
        console.error(error);
      }
    }),
  },

  alerts: {
    data: null,
    alertsTimeWindow: "1d",

    timeWindow: computed((state) => state.alertsTimeWindow),

    formatAlerts: action((state, payload) => {
      let alerts = [];

      if (payload) {
        alerts = payload.map((alert) => {
          return new IoTAlert(
            alert.id,
            alert.applicationId,
            alert.deviceId,
            alert.deviceName,
            alert.orgId,
            alert.enqueuedTime,
            alert.messageSource,
            alert.messageValue,
            alert.templateId,
            alert.ruleName,
            alert.ruleId,
            alert.alertDuration,
            alert.cause,
          );
        });
      }

      state.data = alerts;
    }),

    getAlerts: thunk(async (actions, payload, helpers) => {
      try {
        const globalState = helpers.getStoreState();
        const reqConfig = getEventsReqConfig(globalState, payload);
        //payload is the event type
        await getEvents(
          reqConfig,
          EVENTS_TYPE.alerts,
          globalState.environment,
        ).then((data) => {
          actions.formatAlerts(data);
        });
      } catch (error) {
        console.error(error);
      }
    }),
  },

  audit: {
    data: null,
    auditTimeWindow: "1w",

    timeWindow: computed((state) => state.auditTimeWindow),

    formatAuditEvents: action((state, payload) => {
      let auditEvents = [];

      if (payload) {
        auditEvents = payload.map((auditEvent) => {
          return new IoTAuditEvent(
            auditEvent.id,
            auditEvent.applicationId,
            auditEvent.deviceId,
            auditEvent.orgId,
            auditEvent.enqueuedTime,
            auditEvent.messageSource,
            auditEvent.messageValue,
            auditEvent.templateId,
            auditEvent.actor,
            auditEvent.updated,
            auditEvent.resource,
          );
        });
      }

      state.data = auditEvents;
    }),

    getAuditEvents: thunk(async (actions, payload, helpers) => {
      try {
        const globalState = helpers.getStoreState();
        const reqConfig = getAuditReqConfig(globalState, payload);
        //payload is the event type
        await getEvents(
          reqConfig,
          EVENTS_TYPE.audit,
          globalState.environment,
        ).then((data) => {
          actions.formatAuditEvents(data);
        });
      } catch (error) {
        console.error(error);
      }
    }),
  },
});

export default store;
