/**
 * Representation of one of thee operands inside the Alert condition which is inside the Rule.
 * @param {string} name The name of the operand contains the telemetry property name such as "turb" or "ph". Please see the static class OperandName above.
 * @param {Object} value The value for this operand can be of types below. Please see "type" param.
 * @param {string} type The type of the value for this operand: number, string, boolean
 */
export default class Operand {
  name;
  value;
  type;

  constructor(name, value, type = "number") {
    this.name = name;
    this.value = value;
    this.type = type; //string, number, boolean
  }

  clone() {
    return new Operand(this.name, this.value, this.type);
  }

  toJSON() {
    return {
      name: this.name,
      value: this.value,
      type: this.type,
    };
  }
}
