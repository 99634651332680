import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { DateTime } from "luxon";

import NoData from "../components/NoData.mjs";

const columns = [
  { field: "id", headerName: "ID", width: 10 },
  {
    field: "startedAt",
    headerName: "Occured at",
    width: 180,
    valueFormatter: (value, row) => {
      const date = DateTime.fromISO(value /* { zone: "utc" } */);
      return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    editable: false,
  },
  {
    field: "eventType",
    headerName: "Event Type",
    width: 150,
    editable: false,
  },
  {
    field: "eventValue",
    headerName: "Result",
    width: 120,
    editable: false,
  },
  /* {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  }, */
];

export default function Events() {
  const getEvents = useStoreActions((actions) => actions.events.getEvents);
  const eventsArr = useStoreState((state) => state.events.data);
  const [rows, setRows] = React.useState([]);

  //default time window is 1 day
  const nowUTC = DateTime.now().toUTC();
  const from = nowUTC.minus({ days: 1 }).toISO();
  const to = nowUTC.toISO();
  const [timeWindow, setTimeWindow] = React.useState({ from, to });

  useEffect(() => {
    getEvents(timeWindow);
  }, []);

  useEffect(() => {
    if (eventsArr?.length >= 0) {
      //prepare rows
      setRows(
        eventsArr.map((event, index) => {
          return {
            id: index + 1,
            startedAt: event.enqueuedTime,
            eventType: event.messageSource,
            eventValue: event.messageValue,
          };
        }),
      );
    }
  }, [eventsArr]);

  return (
    <Box sx={{ marginTop: "16px", width: "100%", height: "90vh" }}>
      {rows.length === 0 ? (
        <NoData />
      ) : (
        <DataGrid
          sx={{
            borderRadius: "10px",
            borderColor: "#2b600033",
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#00000012",
            },
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-footerContainer": {
              background: "#2b600033",
              border: "#2b600033",
            },
            "& .MuiDataGrid-columnHeaders>div": {
              background: "#2b600033!important",
            },
          }}
          disableColumnMenu
          /* disableColumnFilter */
          disableColumnSelector
          disableColumnSorting
          columnHeaderHeight={36}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection={false}
          disableRowSelectionOnClick
        />
      )}
    </Box>
  );
}
