/**
 * Rule is a representation (mock) of IoT Central Rule. We need it because we cannot export rules directly from IoT Central.
 * We make a set these mocked Rules for each municipality. This is done becasue we have to show the user on the app what rules
 * trigger their alerts. Depending on wat municipality the user belongs to they see a different set of alerts.
 *
 * Low pH Alert: 74ae004c-8f9e-41f8-970e-69225e595a6c
 * High pH Alert: b43b289f-33b0-4c91-ad0b-74caaecbc745
 * High Turbidity Alert: d98dc28e-3c96-4194-8fad-98e8f8b80a00
 *
 */
export default class Rule {
  // Static enum-like property usage new Rule(Rule.AlertSeverity.GOOD)
  static AlertSeverity = Object.freeze({
    CRITICAL: "critical",
    DANGER: "danger",
    WARNING: "warning",
    GOOD: "good",
    OFFLINE: "offline",
  });

  //(read-only) when in the organizaitons container we need a way to differentiate between organizations and org details and rules.
  #recordType = "orgRule";
  #id; //IoT Central Rule ID
  #isEnabled;
  #conditions; //array of conditions
  #displayName;
  #deviceTemplate; //iot central template name
  #orgId; //parent organization id(usually municipality organization id)
  #severity; //AlertSeverity

  constructor(
    id = null,
    orgId = null,
    isEnabled = true,
    conditions = [],
    displayName = "n/a",
    deviceTemplate = "n/a",
    severity = "good",
  ) {
    this.#id = id;
    this.#isEnabled = isEnabled;
    this.#conditions = conditions;
    this.#displayName = displayName;
    this.#deviceTemplate = deviceTemplate;
    this.#orgId = orgId;
    this.#severity = severity;
  }

  get recordType() {
    return this.#recordType;
  }

  get id() {
    return this.#id;
  }

  set id(id) {
    this.#id = id;
  }

  get orgId() {
    return this.#orgId;
  }

  set orgId(orgId) {
    this.#orgId = orgId;
  }

  get isEnabled() {
    return this.#isEnabled;
  }

  set isEnabled(isEnabled) {
    this.#isEnabled = isEnabled;
  }

  get conditions() {
    return this.#conditions;
  }

  set conditions(conditions) {
    this.#conditions = conditions;
  }

  get displayName() {
    return this.#displayName;
  }

  set displayName(displayName) {
    this.#displayName = displayName;
  }

  get deviceTemplate() {
    return this.#deviceTemplate;
  }

  set deviceTemplate(deviceTemplate) {
    this.#deviceTemplate = deviceTemplate;
  }

  get severity() {
    return this.#severity;
  }

  set severity(severity) {
    this.#severity = severity;
  }

  clone() {
    return new Rule(
      this.#id,
      this.#orgId,
      this.#isEnabled,
      this.#conditions.map((cond) => cond.clone()),
      this.#displayName,
      this.#deviceTemplate,
      this.#severity,
    );
  }

  toJSON() {
    return {
      recordType: this.#recordType,
      id: this.#id,
      orgId: this.#orgId,
      isEnabled: this.#isEnabled,
      conditions: this.#conditions.map((cond) => cond.toJSON()),
      displayName: this.#displayName,
      deviceTemplate: this.#deviceTemplate,
      severity: this.#severity,
    };
  }

  toString() {
    return this.#conditions.map((cond) => cond.toString()).join(" AND ");
  }
}
