import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";
import { Box, Button, Skeleton } from "@mui/material";

export default function Landing() {
  const isServiceWorkerActivated = useStoreState(
    (state) => state.isServiceWorkerActivated,
  );
  const loadDeviceHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.getDeviceHierarchy,
  );

  const setServiceWorkerActivated = useStoreActions(
    (actions) => actions.setServiceWorkerActivated,
  );

  const [syncNeeded, setSyncNeeded] = useState(false);

  const performSync = function () {
    //preload the device hierarchy here as it's a slow operation
    loadDeviceHierarchy();
  };

  function startLoadingData(event) {
    console.debug(`Received message from service worker:`, event?.data.msg);
    if (event?.data.msg === "activated") {
      //this happens only once, when the service worker is activated
      setSyncNeeded(true);
      //for store purposes. Marks a ready state.
      setServiceWorkerActivated(true);
    } else {
      console.error("Unhandled message from service worker: ", event?.data.msg);
    }
  }

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.removeEventListener("message", startLoadingData);
      navigator.serviceWorker.addEventListener("message", startLoadingData);
    }
  }, []);

  useEffect(() => {
    if (syncNeeded) {
      performSync();
    }

    setSyncNeeded(false);
  }, [syncNeeded]);

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {!isServiceWorkerActivated ? (
        <Box>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
          <Skeleton
            sx={{ marginTop: "0.2em" }}
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
        </Box>
      ) : (
        <>
          <Box>
            <Button component={Link} to={"/projects"}>
              Your Projects
            </Button>
          </Box>
          {/* <Box>
            <Button component={Link} to={"/forecast"}>
              Weather Forecast Image
            </Button>
          </Box>
          <Box>
            <Button component={Link} to={"/administration"}>
              Administration Image
            </Button>
          </Box> */}
        </>
      )}
    </Box>
  );
}
