import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useStoreActions, useStoreState } from "easy-peasy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * @param {array} contacts
 * @returns {array} Contacts
 */
function RenderContacts({ contacts }) {
  const Contacts = contacts.map((contact) => {
    return (
      <Box
        key={contact.id}
        sx={{
          display: "grid",
          marginBottom: "16px",
          gridTemplateColumns: "80px 1fr",
          gridTemplateRows: "auto auto",
          gap: "5px",
          gridTemplateAreas: `
				"a b"
				"c d"
				"e f"
				"g h"
			`,
        }}
      >
        <Typography sx={{ gridArea: "a", fontWeight: "bold" }}>
          Name:{" "}
        </Typography>
        <Typography
          sx={{ gridArea: "b" }}
        >{`${contact.firstName} ${contact.lastName}`}</Typography>
        <Typography sx={{ gridArea: "c", fontWeight: "bold" }}>
          Email:{" "}
        </Typography>
        <Typography sx={{ gridArea: "d" }}>{contact.email}</Typography>
        <Typography sx={{ gridArea: "e", fontWeight: "bold" }}>
          Phone:{" "}
        </Typography>
        <Typography sx={{ gridArea: "f" }}>{contact.phone}</Typography>
        <Typography sx={{ gridArea: "g", fontWeight: "bold" }}>
          Position:{" "}
        </Typography>
        <Typography sx={{ gridArea: "h" }}>{contact.position}</Typography>
      </Box>
    );
  });

  return Contacts;
}

export default function Device() {
  const { deviceId, orgId } = useParams();
  const [device, setDevice] = useState(null);
  const [projectDescription, setProjectDescription] = useState(null);
  //get the tree, then get the organization by id, then get device by Id
  //then render the darn thing here
  const deviceHierarchy = useStoreState((state) => state.orgHierarchy.data);
  const loadDeviceHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.getDeviceHierarchy,
  );

  useEffect(() => {
    if (deviceHierarchy) {
      const org = deviceHierarchy.getOrganizationById(orgId);
      setDevice(
        org?.devices.filter((device) => device.id === deviceId).at(0) ||
          org.devices.at(0),
      );
      setProjectDescription(org?.projectDescription);
    } else {
      loadDeviceHierarchy();
    }
  }, [deviceHierarchy]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        border: 1,
        borderColor: "common.grey25",
        maxWidth: 360,
        padding: 2,
        paddingBottom: 0,
        borderRadius: "10px",
      }}
    >
      {deviceHierarchy ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="bodyLargeBold"
              sx={{ marginBottom: 1, display: "block" }}
            >
              Device information
            </Typography>
            <Typography>{projectDescription?.address}</Typography>
          </Box>
          <Box>
            <Typography>{`Project Reference: ${projectDescription?.projectReference}`}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography>{"Device state: "}</Typography>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "10px",
                      backgroundColor:
                        device?.connectionState === "connected"
                          ? "common.lightGreen"
                          : "common.grey50",
                      padding: "1px 6px",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <Typography color="common.white">
                      {`${device?.connectionState === "connected" ? "Connected" : "Disconnected"}`}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography>{"Valve position: "}</Typography>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "10px",
                      backgroundColor:
                        device?.valvePosition === 1
                          ? "common.lightGreen"
                          : "common.grey50",
                      padding: "1px 6px",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <Typography color="common.white">{`${device?.valvePosition === 1 ? "Open" : "Closed"}`}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Accordion
              sx={{
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  padding: 0,
                  "& .MuiAccordionSummary-content": {
                    justifyContent: "flex-end",
                  },
                }}
              >
                <Typography>more info</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  border: 0,
                  padding: 0,
                  paddingBottom: "16px",
                }}
              >
                <Box>
                  {projectDescription?.contacts && (
                    <RenderContacts contacts={projectDescription?.contacts} />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      ) : (
        <Skeleton variant="rounded" animation="wave" width={210} height={50} />
      )}
    </Box>
  );
}
