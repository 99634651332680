import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  useScrollTrigger,
  Toolbar,
  ThemeProvider,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import SyncProblemOutlinedIcon from "@mui/icons-material/SyncProblemOutlined";
import SyncDisabledOutlinedIcon from "@mui/icons-material/SyncDisabledOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import _ from "lodash";

import theme from "../pages/Theme";
import logo from "../../public/assets/windows11/WhiteLogo.png";
import MainMenu from "./MainMenu";
import { minWidth } from "@mui/system";

const rxProjects = /projects\//;
const rxEvents = /\/events/;
const rxNotEvents = /^((?!events).)*$/;
const rxAlerts = /\/alerts/;
const rxNotAlerts = /^((?!alerts).)*$/;

/**
 * This component adds the "floating" effect to it's children during scrolling
 */
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true, //delay when user is scrolling
    threshold: 0, //how far to scroll before the listener triggers
  });

  //Return the !clone! of children with elevation if triggered
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function HeadingText({ pageName }) {
  const navigate = useNavigate();
  const withoutFwdSlash = pageName.substring(1);
  const getCurrentProject = useStoreState(
    (state) => state.orgHierarchy.currentProject,
  );
  const projectId = useStoreState((state) => state.currentProjId);
  let name = "";

  const goBack = () => navigate(-1);

  //Determine the page name based on the URL
  if ("projects" === withoutFwdSlash) {
    //Exact match. Project listing page.
    name = "Projects";
  } else if (
    rxProjects.test(withoutFwdSlash) &&
    rxNotEvents.test(withoutFwdSlash) &&
    rxNotAlerts.test(withoutFwdSlash)
  ) {
    //Not exact match. The word projects exists, but there is no events and alerts words in the url.
    //We're on project properties/telemetry page. Render the project name.
    name = getCurrentProject(projectId)?.displayName;
  } else if (rxEvents.test(withoutFwdSlash)) {
    //We're on Events page.
    name = `Events for ${getCurrentProject(projectId)?.displayName}`;
  } else if (rxAlerts.test(withoutFwdSlash)) {
    //We're on alerts page.
    name = `Alerts for ${getCurrentProject(projectId)?.displayName}`;
  } else {
    //some other path
    name = _.upperFirst(withoutFwdSlash);
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton aria-label="Back" onClick={goBack}>
        <ArrowBackOutlinedIcon color="white" />
      </IconButton>

      <Typography variant="h4" color="white" sx={{ ml: 1 }} noWrap>
        {name}
      </Typography>
    </Box>
  );
}

HeadingText.propTypes = {
  pageName: PropTypes.string.isRequired,
};

export default function Header() {
  const currentURL = useLocation().pathname;
  const [isAtRoot, setIsAtRoot] = useState(true);
  const [isAtProjects, setIsAtProjects] = useState(
    currentURL.substring(1).replace(/\/.*/, "") === "projects",
  );

  const pollingId = useStoreState((state) => state.telemetry.polling);
  const pollingHierarchyId = useStoreState(
    (state) => state.orgHierarchy.polling,
  );

  const pollTelemerty = useStoreActions(
    (actions) => actions.telemetry.pollTelemetryForTime,
  );

  const pollHierarchy = useStoreActions(
    (actions) => actions.orgHierarchy.pollHierarchyForTime,
  );

  const setPolling = useStoreActions((actions) => actions.telemetry.setPolling);
  const setHierarchyPolling = useStoreActions(
    (actions) => actions.orgHierarchy.setPolling,
  );

  const stopPolling = useStoreActions(
    (actions) => actions.telemetry.stopPolling,
  );
  const stopHierarchyPolling = useStoreActions(
    (actions) => actions.orgHierarchy.stopPolling,
  );

  const [synced, setSynced] = useState(pollingId > 0 && pollingHierarchyId > 0);

  const [syncStopped, setSyncStopped] = useState(
    pollingId < 0 && pollingHierarchyId < 0,
  );

  const [syncProbem, setSyncProblem] = useState(
    (pollingId < 0 && pollingHierarchyId > 0) ||
      (pollingId > 0 && pollingHierarchyId < 0),
  );

  const toolbarStyle = {
    minHeight: "50px",
  };

  async function handleAutoRefreshChange(e) {
    console.debug("Was Syncing?:", pollingId > 0 && pollingHierarchyId > 0);

    const stopPoll = async function () {
      await stopPolling();
      await stopHierarchyPolling();
    };

    const startPoll = async () => {
      setPolling(-1);
      setHierarchyPolling(-1);
      await pollTelemerty();
      await pollHierarchy();
    };

    await stopPoll();
    await startPoll();

    console.debug("Is Syncing?:", pollingId > 0 && pollingHierarchyId > 0);
  }

  useEffect(() => {
    setIsAtRoot(currentURL === "/");
    setIsAtProjects(currentURL.substring(1).replace(/\/.*/, "") === "projects");
  }, [currentURL]);

  useEffect(() => {
    setSynced(pollingId > 0 && pollingHierarchyId > 0);
    setSyncProblem(
      (pollingId < 0 && pollingHierarchyId > 0) ||
        (pollingId > 0 && pollingHierarchyId < 0),
    );
    setSyncStopped(pollingId < 0 && pollingHierarchyId < 0);
  }, [pollingId, pollingHierarchyId]);

  return (
    <React.Fragment key="left">
      <ElevationScroll>
        <AppBar color="primary" sx={{ height: 50 }}>
          <Toolbar
            style={toolbarStyle}
            disableGutters
            sx={{
              pl: 2,
              pr: 2,
              height: 50,
              minHeight: 50,
              justifyContent: "space-between",
            }}
          >
            {isAtRoot ? (
              <Box
                component="img"
                alt="company logo"
                src={logo}
                sx={{ width: 40 }}
              />
            ) : (
              <Box
                component="img"
                alt="company logo"
                src={logo}
                sx={{ width: 40 }}
              />
            )}

            {isAtRoot ? (
              <Typography variant="h4" color="white" sx={{ ml: 2 }}>
                Welcome to Effusive
              </Typography>
            ) : (
              <HeadingText variant="h3" color="white" pageName={currentURL} />
            )}
            <Box sx={{ display: "flex", flexFlow: "nowrap" }}>
              {isAtProjects && (
                <IconButton
                  onClick={handleAutoRefreshChange}
                  aria-label="autoRefreshSwitch"
                  color="white"
                >
                  {synced && <SyncOutlinedIcon />}
                  {syncProbem && <SyncProblemOutlinedIcon />}
                  {syncStopped && <SyncDisabledOutlinedIcon />}
                </IconButton>
              )}
              <MainMenu />
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <ThemeProvider theme={theme}>
        <Box sx={{ height: 50 }} />
      </ThemeProvider>
    </React.Fragment>
  );
}
