import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { DateTime } from "luxon";

import NoData from "../components/NoData.mjs";

const columns = [
  { field: "id", headerName: "ID", width: 10 },
  {
    field: "startedAt",
    headerName: "Occured at",
    width: 180,
    valueFormatter: (value, row) => {
      const date = DateTime.fromISO(value /* { zone: "utc" } */);
      return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    editable: false,
  },
  {
    field: "initiator",
    headerName: "Initiator",
    width: 200,
    editable: false,
  },
  {
    field: "userType",
    headerName: "User Type",
    width: 100,
    editable: false,
  },
  {
    field: "revisedResource",
    headerName: "Revised Resource",
    width: 200,
    editable: false,
  },
  {
    field: "revisedResourceType",
    headerName: "Resource Type",
    width: 100,
    editable: false,
  },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    editable: false,
  },
  /* {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  }, */
];

export default function Audit() {
  const getAudit = useStoreActions((actions) => actions.audit.getAuditEvents);
  const auditArr = useStoreState((state) => state.audit.data);
  const [rows, setRows] = React.useState([]);

  //default time window is 1 week
  const nowUTC = DateTime.now().toUTC();
  const from = nowUTC.minus({ weeks: 1 }).toISO();
  const to = nowUTC.toISO();
  const [timeWindow, setTimeWindow] = React.useState({ from, to });

  useEffect(() => {
    getAudit(timeWindow);
  }, []);

  useEffect(() => {
    if (auditArr?.length >= 0) {
      //prepare rows
      setRows(
        auditArr.map((audit, index) => {
          return {
            id: index + 1,
            startedAt: audit.enqueuedTime,
            initiator: audit?.actor?.email,
            userType: audit.actor.type,
            revisedResource: audit?.resource.displayName,
            revisedResourceType: audit?.resource?.type,
            action: audit?.updated?.enabled,
          };
        }),
      );
    }
  }, [auditArr]);

  return (
    <Box sx={{ marginTop: "16px", width: "100%", height: "90vh" }}>
      {rows.length === 0 ? (
        <NoData />
      ) : (
        <DataGrid
          sx={{
            borderRadius: "10px",
            borderColor: "#2b600033",
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#00000012",
            },
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-footerContainer": {
              background: "#2b600033",
              border: "#2b600033",
            },
            "& .MuiDataGrid-columnHeaders>div": {
              background: "#2b600033!important",
            },
          }}
          disableColumnMenu
          /* disableColumnFilter */
          disableColumnSelector
          disableColumnSorting
          columnHeaderHeight={36}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection={false}
          disableRowSelectionOnClick
        />
      )}
    </Box>
  );
}
