import IoTEvent from "./IoTEvent.mjs";
import ConstantsHelper from "../ConstantsHelper.mjs";
/**

    {
    "timestamp": "2020-04-06T00:20:15.06Z",
    "action": {
		"displayName": "PipeDream",
		"id": "3e4059fc-933b-4842-97e9-9d991aae80f0",
		"rules": [
			"81361b0e-00c6-4f75-98b3-53b029c56687"
		],
		"type": "WebhookAction",
		"url": "https://eoksezs49ir0e4b.m.pipedream.net"
	},
    "application": {
		"displayName": "wqms",
		"host": "wqms.azureiotcentral.com",
		"id": "3a4113e1-f24b-4172-8c41-e6a034d3461b",
		"subdomain": "wqms"
	},
    "device": {
        "id": "<device_id>",
        "etag": "<etag>",
        "displayName": "Refrigerator Monitor - 1yl6vvhax6c",
        "instanceOf": "dtmi:modelDefinition:wqms:burnabydemo;1",
        "simulated": true,
        "provisioned": true,
        "approved": true,
        "cloudProperties": {
            "City": {
                "value": "Seattle"
            }
        },
        "properties": {
            "deviceinfo": {
                "firmwareVersion": {
                    "value": "1.0.0"
                }
            }
        },
        "telemetry": {
			"ph": {
				"value": 6.08
			}
		}
    },
    "rule": {
		"displayName": "ParklandTestRule",
		"id": "81361b0e-00c6-4f75-98b3-53b029c56687"
	},
}

 */
const ALERT_MSG_SRC = "alertLifecycle";

export default class IoTAlert extends IoTEvent {
  // Static enum-like property usage new IoTAlert(IoTAlert.AlertType.STARTED)
  static AlertType = Object.freeze({
    STARTED: "started",
    ENDED: "ended",
  });

  ruleId;
  ruleName;
  #alertDuration;
  #cause; //only for started alerts
  /* cause:{
			"ph": {
				"value": 6.08
			}
		}
  */
  constructor(
    id,
    applicationId,
    deviceId,
    deviceName,
    org = null,
    enqueuedTime,
    messageSource = ALERT_MSG_SRC,
    messageType = null,
    templateId,
    ruleName,
    ruleId,
    alertDuration = null,
    cause = null,
  ) {
    super(
      id,
      applicationId,
      deviceId,
      deviceName,
      org,
      enqueuedTime,
      messageSource,
      messageType,
      templateId,
    );
    this.ruleName = ruleName;
    this.ruleId = ruleId;
    this.#alertDuration = alertDuration;
    this.#cause = cause;
  }

  get cause() {
    let causeTxt = "";
    if (this.#cause) {
      Object.keys(this.#cause).forEach((key) => {
        //skip the triggerIoTCentralRule key, because it is not a water property
        if (ConstantsHelper.OperandName.IS_RULE_TRIGGERED !== key) {
          causeTxt = causeTxt.concat(
            `${ConstantsHelper.WaterPropertyDictionary[key]} was ${this.#cause[key]?.value}\n`,
          );
        }
      });
    }

    return causeTxt;
  }

  set cause(value) {
    this.#cause = value;
  }

  get alertDuration() {
    let alertDurationTxt = "";
    if (this.#alertDuration) {
      Object.keys(this.#alertDuration).forEach((key) => {
        //skip the triggerIoTCentralRule key, because it is not a water property
        if (0 !== this.#alertDuration[key]) {
          alertDurationTxt = alertDurationTxt.concat(
            `${this.#alertDuration[key]} ${key} `,
          );
        }
      });
    }

    return alertDurationTxt;
  }

  clone() {
    return new IoTAlert(
      this.id,
      this.applicationId,
      this.deviceId,
      this.deviceName,
      this.orgId,
      this.enqueuedTime,
      this.messageSource,
      this.messageValue,
      this.templateId,
      this.ruleName,
      this.ruleId,
      this.#alertDuration,
      this.#cause,
    );
  }

  toJSON() {
    return {
      id: this.id,
      applicationId: this.applicationId,
      deviceId: this.deviceId,
      deviceName: this.deviceName,
      orgId: this.orgId,
      enqueuedTime: this.enqueuedTime,
      messageSource: this.messageSource,
      messageValue: this.messageValue,
      templateId: this.templateId,
      ruleName: this.ruleName,
      ruleId: this.ruleId,
      alertDuration: this.#alertDuration,
      cause: this.#cause,
    };
  }
}
