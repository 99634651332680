import React from "react";
import { Box, Skeleton } from "@mui/material";
import { useStoreState } from "easy-peasy";

import AzureMapComponent from "../components/AzureLocationMap.mjs";

export default function Projects() {
  const deviceHierarchy = useStoreState((state) => state.orgHierarchy.data);

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {!deviceHierarchy?.tree ? (
        <Box>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
          <Skeleton
            sx={{ marginTop: "0.2em" }}
            variant="rounded"
            animation="wave"
            width={210}
            height={70}
          />
        </Box>
      ) : (
        <AzureMapComponent />
      )}
    </Box>
  );
}
