import _ from "lodash";
/**
 *
 Device connectivity, life cycle (audit has two more properties actor (and updated* only if messageType === "updated")):
 {
	"applicationId": "3a4113e1-f24b-4172-8c41-e6a034d3461b",
	"deviceId": "2otk18kftxe",
	"enqueuedTime": "2024-08-30T17:07:43.254Z",
	"enrichments": {
		"deviceName": "Parkland Refinery ",
		"org": [
			"parkland-refinery-29wrff69v2m"
		]
	},
	"messageSource": "deviceConnectivity",
	"messageType": "disconnected",
	"schema": "default@v1",
	"templateId": "dtmi:modelDefinition:wqms:burnabydemo;1"
}
 */

export default class IoTEvent {
  id;
  applicationId;
  deviceId;
  deviceName;
  orgId;
  enqueuedTime;
  messageSource;
  messageValue;
  templateId;

  constructor(
    id,
    applicationId,
    deviceId,
    deviceName,
    org,
    enqueuedTime,
    messageSource,
    messageType = null,
    templateId,
  ) {
    this.id = id;
    this.applicationId = applicationId;
    this.deviceId = deviceId;
    this.deviceName = deviceName;
    this.orgId = _.isArray(org) ? org.at(0) : org;
    this.enqueuedTime = enqueuedTime;
    this.messageSource = messageSource;
    this.messageValue = messageType;
    this.templateId = templateId;
  }

  clone() {
    return new IoTEvent(
      this.id,
      this.applicationId,
      this.deviceId,
      this.deviceName,
      this.orgId,
      this.enqueuedTime,
      this.messageSource,
      this.messageValue,
      this.templateId,
    );
  }

  toJSON() {
    return {
      id: this.id,
      applicationId: this.applicationId,
      deviceId: this.deviceId,
      deviceName: this.deviceName,
      orgId: this.orgId,
      enqueuedTime: this.enqueuedTime,
      messageSource: this.messageSource,
      messageValue: this.messageValue,
      templateId: this.templateId,
    };
  }
}
