import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Box, Typography, Skeleton, Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Rule({ rule }) {
  return (
    <Box
      sx={{
        border: "1px solid #0000001a",
        borderRadius: "10px",
        minWidth: 385,
        maxWidth: 460,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        flexWrap: "wrap",
        padding: "10px 20px",
      }}
    >
      <Box>
        <CheckCircleIcon
          sx={{
            color: rule?.isEnabled ? "common.lightGreen" : "common.lightGrey",
            marginRight: 2,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "75%",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginBottom: 1 }}>
          <Typography variant="bodyBold">{rule.displayName}</Typography>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            minWidth: "75%",
          }}
        >
          <Box>
            <Typography>{rule.toString()}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

Rule.propTypes = { rule: PropTypes.object };

function RuleList({ rules }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        gap: "16px",
        marginTop: "20px",
      }}
    >
      {rules.map((rule) => (
        <Rule key={rule.id} rule={rule} />
      ))}
    </Box>
  );
}

RuleList.propTypes = {
  rules: PropTypes.array,
};

export default function Rules() {
  const getRules = useStoreActions((actions) => actions.rules.getRules);

  const alertRules = useStoreState((state) => state.rules.data);

  useEffect(() => {
    getRules();
  }, []);

  return (
    <Box component="section">
      {!alertRules?.length ? (
        <Skeleton variant="rounded" animation="wave" width={210} height={60} />
      ) : (
        <RuleList rules={alertRules} />
      )}
    </Box>
  );
}
