export default class ConstantsHelper {
  static WaterPropertyDictionary = Object.freeze({
    turb: "Turbidity",
    ph: "pH",
    temp: "Water Temperature (C)",
    tempc: "Air Temperature (C)",
    rain: "Rainfall (mm)",
    dischflow: "Discharge Flow (L/s)",
    dischvol: "Discharge Volume (L)",
    flowval: "Flow Valve Position",
    recirflow: "Recirculation Flow (L/s)",
    recirvol: "Recirculation Volume (L)",
    triggerIoTCentralRule: "IoT Central Rule Triggered", //this is not a water property, but a custom property used to trigger the IotCentral rule once.
  });

  static OperandName = Object.freeze({
    PH: "ph",
    TURBIDITY: "turb",
    AIR_TEMP: "tempc",
    WATER_TEMP: "temp",
    RAIN: "rain",
    VALVE_POSITION: "flowval",
    DISCHARGE_FLOW: "dischflow",
    DISCHARGE_VOLUME: "dischvol",
    RECIRCULATION_FLOW: "recirflow",
    RECIRCULATION_VOLUME: "recirvol",
    IS_RULE_TRIGGERED: "triggerIoTCentralRule", //this is internal and doesn't show up in UI
  });
}
